<template>
  <AlertBox type="warning" :messages="responseError" />
  <SetLoader v-if="!historyLoaded" />
  <template v-else>
    <div
      class="accordion mt-2 mb-3"
      :class="{ 'mt-2': !$isMobile(), 'mt-0': $isMobile() }"
      :id="String.format('Accordion_{0}', gridId)"
      ref="accordion"
    >
      <div class="accordion-item">
        <h2
          class="accordion-header"
          :id="String.format('Accordion_{0}_head', gridId)"
        >
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="String.format('#Accordion_{0}_tab1', gridId)"
            aria-expanded="true"
            :aria-controls="String.format('Accordion_{0}_tab1', gridId)"
          >
            <p class="text-capitalize fs-5 mb-0">
              {{
                $t(
                  "Components.PageFilter.Title",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </p>
          </button>
        </h2>
        <div
          :id="String.format('Accordion_{0}_tab1', gridId)"
          class="accordion-collapse collapse show"
          :aria-labelledby="String.format('Accordion_{0}_head', gridId)"
          :data-bs-parent="String.format('Accordion_{0}', gridId)"
        >
          <div class="accordion-body">
            <form class="form w-100" id="historyForm">
              <table class="table table-hover fs-5 shadow-none">
                <tbody>
                  <tr>
                    <td class="align-middle p-2">
                      <label for="" class="form-label">{{
                        $t(
                          "History.ActionType",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                    </td>
                    <td class="align-middle p-2">
                      <Select2
                        :placeholder="
                          $t(
                            'BaseModelFields.Choose',
                            {},
                            {
                              locale: this.$store.state.activeLang,
                            }
                          )
                        "
                        class="select2-parent-container"
                        id="historyFilterActionType"
                        :options="actionTypes"
                        :settings="settings"
                        v-model="historyFieldActionType"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle p-2">
                      <label for="historyFilterStartDate" class="form-label">{{
                        $t(
                          "History.StartDate",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                    </td>
                    <td class="align-middle p-2">
                      <DatePicker
                        ref="startDate"
                        type="datetime"
                        id="historyFilterStartDate"
                        :value="historyFilterStartDate"
                        @valueChanged="fieldValueChangeForStartDate"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td class="align-middle p-2">
                      <label for="historyFilterEndDate" class="form-label">{{
                        $t(
                          "History.EndDate",
                          {},
                          { locale: this.$store.state.activeLang }
                        )
                      }}</label>
                    </td>
                    <td class="align-middle p-2">
                      <DatePicker
                        ref="endDate"
                        type="datetime"
                        id="historyFilterEndDate"
                        :minDate="historyFilterStartDate"
                        :value="historyFilterEndDate"
                        @valueChanged="fieldValueChangeForEndDate"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="col-md-12 text-end">
                <button
                  type="button"
                  class="btn btn-success btn-history-filter-search me-2"
                  @click="onSearch('btn-history-filter-search')"
                >
                  <span>
                    {{
                      $t(
                        "BaseModelFields.Search",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </span>
                </button>
                <button
                  type="button"
                  class="btn btn-history-filter-clear btn-danger"
                  @click="onClear"
                >
                  {{
                    $t(
                      "BaseModelFields.Clear",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <StaticGrid
      :settings="gridSettings"
      :columns="gridColumns"
      @onRequestFinally="onSearchEnd"
    />
  </template>
  <HistoryDetailOrCompareModal />
</template>
<script>
import HistoryDetailOrCompareModal from "@/layout/extras/HistoryDetailOrCompareModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Select2 from "vue3-select2-component";
import $ from "jquery";

import DatePicker from "@/components/devexpress/dateboxes/DatePickerByType.vue";
import StaticGrid from "@/components/devexpress/grid/static/Grid.vue";

export default {
  name: "HISTORY",
  props: ["key", "id"],
  watch: {
    "$route.params.key"() {
      if (this.$route.name === "history" && this.formShow) {
        this.getHistoryInfo();
      }
    },
    "$route.params.id"() {
      if (this.$route.name === "history" && this.formShow) {
        this.getHistoryInfo();
      }
    },
  },
  data() {
    return {
      disabledButtonClasses:
        ".btn-history-filter-search, .btn-history-filter-clear",
      formatInfo: this.$root.getDateTimeFormat(),
      shortFormatInfo: this.$root.getDateTimeFormat("short"),
      historyFilterStartDate: null,
      historyFilterEndDate: null,
      historyFieldActionType: "",
      gridSettings: {
        action: "RecordLogs",
        requestUrl: "",

        isCommandColumnHide: true,
        isPublicIdColumnHide: true,
        allowSearchPanel: true,
        allowExcelExport: true,
        allowPaging: true,

        autoOrderProcess: true,
      },
      gridColumns: [
        {
          text: this.$t(
            "History.User",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "userName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "History.ActionType",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "logType",
          type: "string",
          visible: true,
          width: 0,
          template: "recordLogActionTypeTemplate",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "History.Date",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "History.Source",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "requestFrom",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "History.Information",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "recordData",
          type: "string",
          visible: true,
          width: 200,
          template: "jsonViewerTemplate",
          format: "",
          textAlign: "",
        },
      ],
      actionTypes: [
        {
          id: "1",
          text: this.$t(
            "History.FieldServiceCreateRecord",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          id: "2",
          text: this.$t(
            "History.FieldServiceUpdateRecord",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          id: "3",
          text: this.$t(
            "History.FieldServiceDeleteRecord",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          id: "4",
          text: this.$t(
            "History.FieldServiceUpdateFieldValue",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          id: "5",
          text: this.$t(
            "History.KanbanViewServiceUpdateFieldValue",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          id: "6",
          text: this.$t(
            "History.CalendarViewServiceUpdateFieldValue",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          id: "7",
          text: this.$t(
            "History.FieldServiceGetRecord",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          id: "8",
          text: this.$t(
            "History.FieldServiceExportRecords",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          id: "9",
          text: this.$t(
            "History.FieldServiceBulkUpdateRecords",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          id: "10",
          text: this.$t(
            "History.UserStatusUpdate",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          id: "11",
          text: this.$t(
            "History.FieldServiceBulkImport",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      settings: {
        allowClear: true,
        disabled: false,
        multiple: false,
        dropdownAutoWidth: false,
        theme: "bootstrap-5",
      },
      gridId: String.newGuid(),
      historyLoaded: false,
      formShow: false,
      histories: null,
      responseError: [],
      actionName: "rws-GetRecordLogsOfARecord",
      pageData: {},
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "BaseModelFields.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
    };
  },
  components: {
    DatePicker,
    Select2,
    StaticGrid,
    HistoryDetailOrCompareModal,
  },
  methods: {
    fieldValueChangeForStartDate(val) {
      this.historyFilterStartDate = val;
    },
    fieldValueChangeForEndDate(val) {
      this.historyFilterEndDate = val;
    },
    onSearch(buttonName) {
      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.getHistory();
    },
    onClear() {
      this.historyFilterStartDate = null;
      this.historyFilterEndDate = null;
      this.$refs.startDate.$refs.datePicker.instance.reset();
      this.$refs.endDate.$refs.datePicker.instance.reset();
      $(String.format("#historyFilterActionType")).val(null).trigger("change");
    },
    getHistoryInfo() {
      setCurrentPageBreadcrumbs("", [{ name: "HELP" }]);
      this.formShow = false;

      var key = this.$route.params.key,
        recordPublicId = this.$route.params.id,
        pageLayoutId = this.$route.query.pageLayoutId;

      if (
        String.isNullOrWhiteSpace(key) ||
        String.isNullOrWhiteSpace(recordPublicId)
      ) {
        this.$router.push(this.$appHomeUrl);
        return;
      }

      var historyPageRequestUrl = String.format(
        "rws-SetController-Detail?key={0}&id={1}",
        key,
        recordPublicId
      );

      if (!String.isNullOrWhiteSpace(pageLayoutId)) {
        historyPageRequestUrl += String.format(
          "&pageLayoutId={0}",
          pageLayoutId
        );
      }

      this.$appAxios
        .get(historyPageRequestUrl)
        .then((response) => {
          this.formShow = true;
          this.getHistory();
          this.$store.commit("setPageLayoutAvailable", true);

          if (response.data.isOk) {
            this.pageData = response.data;

            var title = this.$t(
              "Set.HistoryFormat",
              {},
              { locale: this.$store.state.activeLang }
            ).replaceAll("OBJECT_NAME", this.pageData.name);

            this.$root.changeDocumentTitle(title);
            setCurrentPageBreadcrumbs(title, [
              {
                name: this.pageData.customObjectPluralName,
                isRouteLink: true,
                html: String.format("/set/list/{0}", this.$route.params.key),
              },
              {
                name: title,
              },
            ]);
          } else {
            this.$root.changeDocumentTitle(
              this.$t("Components.LostWave.Title"),
              {},
              { locale: this.$store.state.activeLang }
            );
            setCurrentPageBreadcrumbs(
              this.$t(
                "Components.LostWave.Title",
                {},
                { locale: this.$store.state.activeLang }
              ),
              []
            );

            this.responseError.push(response.data.msg);
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    getHistory() {
      var searchUrl = String.format(
        "{0}?customObjectKey={1}&recordPublicId={2}",
        this.actionName,
        this.$route.params.key,
        this.$route.params.id
      );

      var logTypeId = $(String.format("#historyFilterActionType")).val();
      if (!String.isNullOrWhiteSpace(logTypeId)) {
        searchUrl += "&logTypeId=" + logTypeId;
      }

      var startDate = $(String.format("#historyFilterStartDate")).val();
      if (!String.isNullOrWhiteSpace(startDate)) {
        searchUrl += "&startDate=" + startDate;
      }

      var endDate = $(String.format("#historyFilterEndDate")).val();
      if (!String.isNullOrWhiteSpace(endDate)) {
        searchUrl += "&endDate=" + endDate;
      }

      this.gridSettings.requestUrl = ""; //trigger watch parameter
      this.gridSettings.requestUrl = searchUrl;
      this.historyLoaded = true;
    },
    onSearchEnd() {
      var button = $(String.format(".{0}", "btn-history-filter-search")),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);
      firstSpan.show();
      loadingBlock.hide();
      disabledButtons.prop("disabled", false);
    },
  },
  mounted() {
    this.getHistoryInfo();
  },
};
</script>
